import React from "react"
import { css } from '@emotion/css'
import { Link, Element } from 'react-scroll'
import { Spring } from 'react-spring/renderprops'

import Layout from "../shared/Layout2"
import TopSectionMobile from "../shared/TopSectionMobile"
import Section from "../shared/Section"
import SectionTitle from "../shared/SectionTitle"
import LightText from "../shared/LightText"
import MainButton from "../shared/MainButton"
import Container from "../shared/Container"
import DownArrow from "../shared/DownArrow"
import SEO from "../shared/seo"
import NavbarNew from '../shared/NavbarNew'
import EndSection from '../shared/EndSection'

const AutomationPage = ({cmsData}) => {

    return <Layout>
        <NavbarNew darkMode />
        <SEO 
            title={cmsData.seo.title == "-" ? "" : cmsData.seo.title}
            description={cmsData.seo.descr == "-" ? "" : cmsData.seo.descr}
        />

        <div className={css`
            display: none;
            @media (max-width: 1000px) {
                display: block;
            }
        `}>
            <TopSectionMobile imageUrl={cmsData?.bg?.childImageSharp?.fluid?.src}>
                <SectionTitle text={cmsData.title} />
                {cmsData.summaryList?.map(obj => {
                  return <LightText text={obj.text} />
                })}

                <Link to="firstSection" spy={true} smooth={true} duration={800}>
                    <DownArrow />
                </Link>
            </TopSectionMobile>
        </div>

        <div className={css`
            @media (max-width: 1000px) {
                display: none;
            }
        `}>


            <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {Sprops => <div style={Sprops}>
                    <div className="whiteOverlaySection_automation" style={{backgroundImage: `url(${cmsData.bg?.childImageSharp?.fluid?.src})`}}>
                        <div className="whiteOverlay">
                            <Container marginTop>
                                <SectionTitle text={cmsData.title} />
                                {cmsData.summaryList?.map(obj => {
                                  return <LightText text={obj.text} />
                                })}
                                <Link to="firstSection" spy={true} smooth={true} duration={800}>
                                    <DownArrow />
                                </Link>
                            </Container>
                        </div>
                    </div >
                </div>}
            </Spring>
        </div>

        <Element name="firstSection" >
            <Section imageUrl={'/img/pink.png'}>
                <Container>
                    <SectionTitle color="white" text={cmsData.pink.title} />
                    <LightText bottom="120" color="white" text={cmsData.pink.text} />
                    <MainButton to={cmsData.pink.buttonPath} text={cmsData.pink.buttonText} />
                </Container>
            </Section>
        </Element>

        <EndSection to={cmsData.endSection.buttonPath} title={cmsData.endSection.title} iconUrl={cmsData.endSection?.icon?.childImageSharp?.fluid?.src} buttonText={cmsData.endSection.buttonText} />

    </Layout>
}

export default AutomationPage
